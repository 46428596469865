import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    CheckoutCartSummary,
    CheckoutDeliveryOption,
    CheckoutProfile,
    CheckoutState,
    Installment,
    PaymentOptionState,
    SetDeliveryOptionThunkPayload,
    PaymentIframeAccessToken
} from './types'
import { UserAddress } from '../address/types'

const initialState: CheckoutState = {
    profile: {
        authToken: '',
        elegivelRetiraLocal: false,
        transactionType: '',
        transactionSellerCode: '',
        enderecosEntrega: [],
        numeroPedido: 0,
        opcoesEntrega: [],
        opcoesPagamento: [],
        resumoCarrinho: {
            itensPedido: [],
            frete: 0,
            freteText: '',
            desconto: 0,
            descontoText: '',
            valorTotal: 0,
            valorTotalText: '',
            valorTotalAVista: 0,
            valorTotalAVistaText: '',
            valorTotalProdutos: 0,
            valorTotalProdutosText: '',
            usuarioDePontos: false,
            valorTotalEmPontos: 0,
            saldoPontos: 0,
            cupons: []
        }
    },
    deliveryAddress: undefined,
    deliveryOption: undefined,
    selectedPaymentMethod: undefined,
    selectedInstallment: undefined,
    paymentIframeAccessToken: undefined
}

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        _onGetCheckoutProfileSuccess(
            state,
            action: PayloadAction<CheckoutProfile>
        ) {
            state.profile = action.payload
        },
        _onSetDeliveryAddressSuccess(
            state,
            action: PayloadAction<{
                deliveryAddressId: string
                numeroPedido: number
                cartSummary: CheckoutCartSummary
                opcoesEntrega: CheckoutDeliveryOption[]
            }>
        ) {
            state.profile.numeroPedido = action.payload.numeroPedido
            state.profile.resumoCarrinho = action.payload.cartSummary
            state.profile.opcoesEntrega = action.payload.opcoesEntrega
            state.deliveryAddress = state.profile.enderecosEntrega.find(
                address =>
                    address.idEndereco === action.payload.deliveryAddressId
            )
            state.deliveryOption = undefined
            state.selectedPaymentMethod = undefined
            state.selectedInstallment = undefined
        },
        _onSetDeliveryOptionSuccess(
            state,
            action: PayloadAction<{
                cartSummary: CheckoutCartSummary
                deliveryOption: SetDeliveryOptionThunkPayload
            }>
        ) {
            state.profile.resumoCarrinho = action.payload.cartSummary
            state.deliveryOption = action.payload.deliveryOption
            state.selectedPaymentMethod = undefined
            state.selectedInstallment = undefined
        },
        _onSetPaymentMethodSuccess(
            state,
            action: PayloadAction<{
                cartSummary: CheckoutCartSummary
                paymentOption: PaymentOptionState
                numeroPedido?: number
            }>
        ) {
            state.profile.numeroPedido =
                action.payload.numeroPedido || state.profile.numeroPedido
            state.profile.resumoCarrinho = action.payload.cartSummary
            state.selectedPaymentMethod = action.payload.paymentOption
            state.selectedInstallment = undefined
        },
        _onSetPaymentPointsAmountSuccess(state) {
            state.selectedInstallment = undefined
        },
        _onSetInstallmentSuccess(
            state,
            action: PayloadAction<{
                installmentOption: Installment
                cartSummary: CheckoutCartSummary
            }>
        ) {
            state.selectedInstallment = action.payload.installmentOption
            state.profile.resumoCarrinho = action.payload.cartSummary
        },
        _onSetPaymentIframeAccessToken(
            state,
            action: PayloadAction<PaymentIframeAccessToken>
        ) {
            state.paymentIframeAccessToken = action.payload
        },
        setDeliveryAddressId(state, action: PayloadAction<string>) {
            state.deliveryAddress = {
                ...state.deliveryAddress,
                idEndereco: action.payload
            } as UserAddress
        },
        setDeliveryAddress(
            state,
            action: PayloadAction<{
                addressId: string
                enderecosEntrega: UserAddress[]
            }>
        ) {
            state.profile.enderecosEntrega = action.payload.enderecosEntrega
            state.deliveryAddress = action.payload.enderecosEntrega.find(
                address => address.idEndereco === action.payload.addressId
            )
        },
        clearCheckout() {
            return initialState
        }
    }
})

export const checkoutActions = checkoutSlice.actions

export default checkoutSlice
