import { UserEmailModal } from '@features/myAccount/components/userDataPF/UserEmailModal/io'
import { DefaultErrorThunkOptions, ThunkOptions } from '@store/types'
import { handleErrorResponse } from '@store/utils'
import { verifyTelevendedorExists } from '@utils/user'
import { authActions } from '../auth/slice'
import { cartActions } from '../cart/slice'
import { AppThunk } from '../store'
import {
    changePassword,
    getCNPJStatus,
    getPersonalizations,
    getUserData,
    getUserLogin,
    getUserPoints,
    getTelevendasChannelData,
    postUsersPF,
    postUsersPj,
    sendLinkToUserEmail,
    setUserData,
    setUserEmail,
    setUserPassword,
    updateUserPj,
    deactivateUserAccount
} from './services'
import { userActions } from './slice'
import {
    CNPJStatus,
    GetUserPoints,
    PFUserRegisterData,
    PJUserRegisterData,
    TelevendaChannel,
    UpdatedUserData,
    UserChangePasswordData,
    UserData,
    UserPasswordData,
    UserPointsData
} from './types'
import { logoutCommonUserThunk } from '@store/modules/auth/thunk'

export const updatePersonalizationsThunk = (): AppThunk => async dispatch => {
    try {
        const personalizations = await getPersonalizations()

        dispatch(userActions._onUpdatePersonalizationsSuccess(personalizations))
        dispatch(
            cartActions.setNumberOfItemsInCart(personalizations.badgeCarrinho)
        )
        dispatch(
            authActions.setTelevendedorAuthenticated(
                verifyTelevendedorExists(personalizations.nomeTelevendedor)
            )
        )
    } catch (error: any) {
        handleErrorResponse(error)
    }
}

export const registerPFThunk =
    (
        user: PFUserRegisterData,
        { onSuccess, onError }: DefaultErrorThunkOptions
    ): AppThunk =>
    async dispatch => {
        try {
            await postUsersPF(user)
            dispatch(authActions._onLoginSuccess())
            onSuccess && onSuccess()
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'onEmptyMessage'
            })
        }
    }

export const changePasswordThunk =
    (
        passwords: UserChangePasswordData,
        { onSuccess, onError }: DefaultErrorThunkOptions
    ): AppThunk =>
    async () => {
        try {
            await changePassword(passwords)
            onSuccess && onSuccess()
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'always'
            })
        }
    }

export const registerPJThunk =
    (
        user: PJUserRegisterData,
        { onSuccess, onError }: DefaultErrorThunkOptions
    ): AppThunk =>
    async dispatch => {
        try {
            await postUsersPj(user)
            dispatch(authActions._onLoginSuccess())
            onSuccess && onSuccess()
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'onEmptyMessage'
            })
        }
    }

export const updateUserPjThunk =
    (
        user: PJUserRegisterData,
        { onSuccess, onError }: DefaultErrorThunkOptions
    ): AppThunk =>
    async () => {
        try {
            await updateUserPj(user)
            onSuccess && onSuccess()
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'always'
            })
        }
    }

export const checkCNPJStatusThunk =
    (
        cnpj: string,
        { onSuccess, onError }: ThunkOptions<CNPJStatus>
    ): AppThunk =>
    async () => {
        try {
            const data = await getCNPJStatus(cnpj)
            onSuccess && onSuccess(data)
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'onEmptyMessage'
            })
        }
    }

export const getUserDataThunk =
    ({ onSuccess, onError }: ThunkOptions<UserData> = {}): AppThunk =>
    async dispatch => {
        try {
            const userData = await getUserData()
            dispatch(userActions._setUserId(userData.idUsuario))
            onSuccess && onSuccess(userData)
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'onEmptyMessage'
            })
        }
    }

export const setUserDataRegistrationThunk =
    (
        userData: UpdatedUserData,
        { onSuccess, onError }: ThunkOptions<UserData>
    ): AppThunk =>
    async () => {
        try {
            const data = await setUserData(userData)
            onSuccess && onSuccess(data)
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'onEmptyMessage'
            })
        }
    }

export const setUserEmailThunk =
    (
        emailForm: UserEmailModal,
        { onSuccess, onError }: DefaultErrorThunkOptions
    ): AppThunk =>
    async () => {
        try {
            await setUserEmail(emailForm)
            onSuccess && onSuccess()
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'onEmptyMessage'
            })
        }
    }

export const setUserPasswordThunk =
    (
        passwordForm: UserPasswordData,
        { onSuccess, onError }: DefaultErrorThunkOptions
    ): AppThunk =>
    async dispatch => {
        try {
            await setUserPassword(passwordForm)
            dispatch(authActions._onLoginSuccess())
            onSuccess && onSuccess()
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'onEmptyMessage'
            })
        }
    }

export const sendLinkToUserEmailThunk =
    (
        login: string,
        { onSuccess, onError }: DefaultErrorThunkOptions
    ): AppThunk =>
    async () => {
        try {
            await sendLinkToUserEmail(login)
            onSuccess && onSuccess()
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'onEmptyMessage'
            })
        }
    }

export const getUsernameThunk =
    (
        login: string,
        { onSuccess, onError }: DefaultErrorThunkOptions<string>
    ): AppThunk =>
    async () => {
        try {
            const maskedUsername = await getUserLogin(login)
            onSuccess && onSuccess(maskedUsername)
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'onEmptyMessage'
            })
        }
    }

export const getUserPointsThunk =
    (
        query: GetUserPoints,
        { onSuccess, onError }: ThunkOptions<UserPointsData>
    ): AppThunk =>
    async () => {
        try {
            const userPointsResult = await getUserPoints(query)
            onSuccess && onSuccess(userPointsResult)
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'always'
            })
        }
    }

export const getTelevendasChannelDataThunk =
    ({ onSuccess, onError }: ThunkOptions<TelevendaChannel[]> = {}): AppThunk =>
    async dispatch => {
        try {
            const televendasChannelData = await getTelevendasChannelData()
            dispatch(
                userActions.getTelevendasChannelDataSuccess(
                    televendasChannelData
                )
            )
            onSuccess && onSuccess(televendasChannelData)
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'always'
            })
        }
    }

export const deactivateUserAccountThunk =
    ({ onSuccess, onError }: DefaultErrorThunkOptions): AppThunk =>
    async dispatch => {
        try {
            await deactivateUserAccount()
            dispatch(logoutCommonUserThunk({ onSuccess, onError }))
        } catch (error: any) {
            handleErrorResponse(error, {
                onError,
                visibility: 'always'
            })
        }
    }
