import { Button as MuiButton, ButtonProps } from '@material-ui/core'
import { themeStyled } from '@theme/theme'
import styled, { css } from 'styled-components'

const buttonColors = {
    gray: {
        default: themeStyled.colors.gray[500],
        hover: themeStyled.colors.gray[200]
    },
    primary: {
        default: themeStyled.colors.primary[500],
        hover: themeStyled.colors.primary[800]
    },
    secondary: {
        default: themeStyled.colors.secondary[800],
        hover: themeStyled.colors.secondary[900]
    },
    green: {
        default: themeStyled.colors.green[500],
        hover: themeStyled.colors.green[600]
    },
    white: {
        default: themeStyled.colors.white,
        hover: themeStyled.colors.gray[100]
    },
    red: {
        default: themeStyled.colors.red[500],
        hover: themeStyled.colors.red[700]
    }
}

type ColorType = {
    default: string
    hover: string
}

export type ColorsNameType = keyof typeof buttonColors

const getColor = (color: ColorsNameType): ColorType => buttonColors[color]

const SmallButton = css`
    min-height: 40px;
    font-size: 12px;
`

const MediumButton = css`
    min-height: 48px;
    font-size: 14px;
`

const getSizeStyles = (size: ButtonProps['size']) => {
    if (size === 'medium') return MediumButton
    return SmallButton
}

type ButtonStyledProps = {
    $colorName: ColorsNameType
    $size: ButtonProps['size']
    ref: React.ForwardedRef<HTMLButtonElement>
}

const TextButton = css<ButtonStyledProps>`
    color: ${({ $colorName }) => getColor($colorName).default};

    &:hover {
        color: ${({ $colorName }) => getColor($colorName).hover};
    }
`

const ContainedButton = css<ButtonStyledProps>`
    background-color: ${({ $colorName }) => getColor($colorName).default};
    color: ${({ theme, $colorName }) =>
        $colorName !== 'white' ? theme.colors.white : theme.colors.gray[700]};

    &:hover {
        background-color: ${({ $colorName }) => getColor($colorName).hover};
    }
`

const OutlinedButton = css<ButtonStyledProps>`
    border: 1px solid;
    border-color: ${({ $colorName }) =>
        $colorName === 'gray'
            ? getColor($colorName).hover
            : getColor($colorName).default};
    color: ${({ $colorName }) => getColor($colorName).default};

    &:hover {
        border-color: ${({ $colorName }) => getColor($colorName).hover};
        color: ${({ $colorName }) => getColor($colorName).hover};
    }
`

const getVariantStyles = (variant: ButtonProps['variant']) => {
    if (variant === 'outlined') return OutlinedButton
    if (variant === 'text') return TextButton
    return ContainedButton
}

const Button = styled(MuiButton)<ButtonStyledProps>`
    font-family: ${({ theme }) => theme.fontFamily.sansSerif};
    font-weight: ${({ theme }) => theme.fontWeight.sansSerifMedium};
    border-radius: 6px;
    text-transform: none;
    line-height: 17px;
    padding-left: 18px;
    padding-right: 18px;

    .MuiButton-startIcon {
        margin-right: 10px;
    }

    ${({ $size }) => getSizeStyles($size)}
    ${({ variant }) => getVariantStyles(variant)}
`

export { Button }
